@tailwind base;
@tailwind components;
@tailwind utilities;

.container {
  width: 100%;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

.experience-container {
  margin: 8rem 2rem;
  position: relative;
}

.experience-container h5 {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 3rem;
}

.arrow-left,
.arrow-right {
  width: 3.2rem;
  height: 3.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(90deg, rgb(6 182 212) 0%, #3b82f6 100%);
  border-radius: 5rem;
  border-image-slice: 1;
  position: absolute;
  top: 55%;
  z-index: 2;
  cursor: pointer;
  user-select: none;
}


.arrow-left span,
.arrow-right span {
  font-size: 2.5rem;
  font-weight: 500;
}

.arrow-left {
  left: -1%;
}

.arrow-right {
  right: -1%;
}

@media (max-width: 768px) {
  .experience-container {
    margin: 4rem auto;
  }

  .experience-container h5 {
    font-size: 1.3rem;
    margin-bottom: 2rem;
  }

  .arrow-left {
    left: -1rem;
  }

  .arrow-right {
    right: -1rem;
  }
}

.slick-dots li button::before {
  color:White !important;
  background:
}