@tailwind base;
@tailwind components;
@tailwind utilities;

.container {
  width: 100%;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

.work-experience-card {
  border-radius: 0.65rem;
  padding: 2rem 2.5rem;
  margin: 0 1rem;
  box-shadow: 
    inset 0 0 0 0.1px rgba(6, 182, 212, 0.9),
    inset 0 0 10px 1px rgba(6, 182, 212, 0.6),
    inset 0 0 20px 2px rgba(59, 130, 246, 0.8); 
}

.work-experience-card h6 {
  font-size: 1.25rem;
  font-weight: 1000;
  margin-bottom: 0.7rem;
}

.work-duration {
  display: inline-block;
  font-size: 0.8rem;
  font-weight: 400;
  background: linear-gradient(90deg, rgb(6 182 212) 0%, #3b82f6 100%);
  border-radius: 0.3rem;
  padding: 0.4rem 0.6rem;
  margin-bottom: 1.3rem;
}

.work-experience-card ul li {
  list-style: none;
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 0.5rem;
  position: relative;
}

.work-experience-card ul li::after {
  content: " ";
  width: 0.5rem;
  height: 0.5rem;
  background-color: rgb(6 182 212);
  border-radius: 0.5rem;
  position: absolute;
  left: -1.3rem;
  top: 0.35rem;
}

@media (max-width: 768px) {
  .work-experience-card {
    margin: 0.75rem;
  }

  .work-experience-card h6 {
    font-size: 1.2rem;
  }

  .work-experience-card ul li {
    font-size: 0.8rem;
  }

  .work-duration {
    font-size: 0.7rem;
  }
}