@tailwind base;
@tailwind components;
@tailwind utilities;

.zoom-in {
  transition: transform 0.3s ease-in-out;
  /* Updated transition shorthand */
}

.zoom-in:hover {
  transform: translateY(-1px) scale(1.05);
  transition-duration: 150ms;
}

.container {
  width: 100%;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

.projects-container {
  margin: 4rem 2rem;
  position: relative;
}

.projects-container::after,
.projects-container::before {
  content: " ";
  width: 28rem;
  height: 28rem;
  border-radius: 28.125rem;
  background: linear-gradient(90deg, rgb(6 182 212) 0%, #3b82f6 100%);
  position: absolute;
  z-index: -100;
  opacity: 0.3;
  filter: blur(60px);
  -webkit-filter: blur(60px);
}

.projects-container::after {
  top: -3rem;
  left: -5rem;
}

.projects-container::before {
  background: linear-gradient(90deg, rgb(6 182 212) 0%, #3b82f6 100%);
  bottom: 0rem;
  right: 0rem;
}

.projects-container h5 {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 3.5rem;
}

.projects-content {
  display: flex;
  align-items: flex-start;
  gap: 3rem;
}

.projects {
  flex: 1;
  display: grid;
  grid-gap: 3rem;
  grid-template-columns: repeat(2, 1fr);
}

.projects-info {
  flex: 1;
}

.mx-2 {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.mb-2 {
  margin-bottom: 0.5rem;
}

.me-2 {
  margin-inline-end: 0.5rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.mt-8 {
  margin-top: 2rem;
}

.grid {
  display: grid;
}

.grid-cols-5 {
  grid-template-columns: repeat(5, minmax(0, 1fr));
}

.gap-5 {
  gap: 1.25rem;
}

.rounded-full {
  border-radius: 9999px;
}

.bg-gradient-to-bl {
  background-image: linear-gradient(to bottom left, var(--tw-gradient-stops));
}

.from-cyan-500 {
  --tw-gradient-from: #06b6d4 var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(6 182 212 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-50 {
  --tw-gradient-from: #f8fafc var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(248 250 252 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-100 {
  --tw-gradient-from: #f4f4f5 var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(244 244 245 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.via-slate-100 {
  --tw-gradient-to: rgb(241 245 249 / 0)  var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #f1f5f9 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-zinc-200 {
  --tw-gradient-to: rgb(228 228 231 / 0)  var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #e4e4e7 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.to-blue-500 {
  --tw-gradient-to: #3b82f6 var(--tw-gradient-to-position);
}

.to-slate-200 {
  --tw-gradient-to: #e2e8f0 var(--tw-gradient-to-position);
}

.to-zinc-300 {
  --tw-gradient-to: #d4d4d8 var(--tw-gradient-to-position);
}

.bg-clip-text {
  -webkit-background-clip: text;
  background-clip: text;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.font-bold {
  font-weight: 700;
}

.text-transparent {
  color: transparent;
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

@media (min-width: 640px) {
  .sm\:grid-cols-8{
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }
}

@media (min-width: 768px) {
  .md\:grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }
}

@media (min-width: 1024px) {
  .lg\:grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }
}

@media (min-width: 1280px) {
  .xl\:grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr));
  }
} 

@media (max-width: 1025px) {
  .projects {
    padding-left: 1rem;
  }

  .projects,
  .projects-content {
    grid-gap: 2rem;
  }
}

@media (max-width: 768px) {
  .projects-content {
    flex-direction: column-reverse;
    gap: 3rem;
  }
  .projects-container {
    margin: 4rem auto;
  }

  .projects,
  .projects-info {
    width: 100%;
  }

  .projects-container h5 {
    font-size: 1.3rem;
    margin-bottom: 2rem;
  }

  .projects-container::after,
  .projects-container::before {
    width: 18rem;
    height: 18rem;
  }
}

@media (max-width: 600px) {
  .projects-container {
    padding: 0;
  }
}

.slick-dots li button::before {
  color:White !important;
  background:
}