@tailwind base;
@tailwind components;
@tailwind utilities;

.container {
  width: 100%;
}

#image-container {
  transition: transform 0.3s ease-in-out;
}

#certificate-title {
  opacity: 0;
  user-select: none;
  position: relative;
  z-index: -99;
}

#certificate-card:hover {
  background-image: rgba(0,0,0,1);
  opacity: 30%;
}

#image-container:hover + #certificate-title, #certificate-title:hover {
  transform: translateY(-175px) scale(1.2);
  transition-duration: 500ms;
  opacity: 1;
}

.certificate-container::after,
.certificate-container::before {
  content: " ";
  width: 20rem;
  height: 20rem;
  border-radius: 28.125rem;
  background: linear-gradient(90deg, rgb(6 182 212) 0%, #3b82f6 50%);
  position: absolute;
  z-index: -1;
  opacity: 0.3;
  filter: blur(60px);
  -webkit-filter: blur(60px);
}

.certificate-container::after {
  top: -3rem;
  left: -5rem;
}

.certificate-container::before {
  background: linear-gradient(90deg, rgb(6 182 212) 0%, #3b82f6 100%);
  bottom: 0rem;
  right: 0rem;
}


@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

#certificate {
  margin: 4rem 2rem;
  position: relative;
}

#certificate h5 {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 3rem;
}


@media (max-width: 768px) {

  #certificate {
    margin: 4rem auto;
    position: relative;
  }

  #certificate h5 {
    font-size: 1.3rem;
    margin-bottom: 1.5rem;
  }
}