.project-level {
  display: inline-block;
  font-size: 0.8rem;
  font-weight: 400;
  background: linear-gradient(90deg, rgb(6 182 212) 0%, #3b82f6 100%);
  border-radius: 0.3rem;
  padding: 0.4rem 0.6rem;
  margin-bottom: 0.8rem;
}

.project-card {
    border-radius: 0.65rem;
    box-shadow: 
      inset 0 0 0 0.5px rgba(6, 182, 212, 0.8),
      inset 0 0 15px 1px rgba(6, 182, 212, 0.6),
      inset 0 0 15px 1px rgba(59, 130, 246, 0.8); 
  }

  #project-name {
    font-size: 1.2rem;
  }