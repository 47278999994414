@tailwind base;
@tailwind components;
@tailwind utilities;

.container {
  width: 100%;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

#footer #footer-text {
  text-align: center;
  font: 17px "source sans pro", arial;
  position: relative;
  color: #eee;
}

#footer {
  background: linear-gradient(to right, rgba(47, 58, 78, 0.4), rgba(74, 85, 104, 0.6));
  z-index: 30;
  -webkit-backdrop-filter: blur(50px);
  backdrop-filter: blur(50px);
  padding: 1rem;
  font-size: 0.8rem;
  width: 100%;
  text-align: center;
  position: relative;
}

section {
  margin-bottom: 0 !important;
}
