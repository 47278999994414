@tailwind base;
@tailwind components;
@tailwind utilities;


#branch {
  height: auto;
}

.container {
  width: 100%;
}

.items-center {
  align-items: center !important;
}

#timeline li:nth-child(even) .text-group {
    position: absolute;
    left: -72%;
    text-align-last: right;
}

.education-container {
  margin: 4rem 2rem;
  position: relative;
  height: auto;
  left: 25%;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }

}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

@media (max-width: 1080px) {
    #timeline li:nth-child(even) .text-group {
        left: auto;
        text-align-last: start;
    }
}

.education-container::after,
.education-container::before {
  content: " ";
  border-radius: 28.125rem;
  position: absolute;
  z-index: -1;
  opacity: 0.2;
  filter: blur(60px);
  -webkit-filter: blur(60px);
}

.education-container::after {
    background: linear-gradient(90deg, #2498ff 0%, #3b82f6 100%);
    width: 20rem;
    height: 20rem;
    top: -15rem;
    left: 2rem;
}

.education-container::before {
  background: linear-gradient(90deg, #3b82f6 0%, #2498ff 100%);
  width: 20rem;
  height: 20rem;
  bottom: 5rem;
  right: 18rem;
}
.education-h5 {
    font-size: 1.5rem;
    font-weight: 600;
    margin: 4rem 2rem;
}
.education-container h5 {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 3.5rem;
}

.education-content {
  display: flex;
  align-items: flex-start;
  gap: 3rem;
}

.education {
  flex: 1;
  display: grid;
  grid-gap: 3rem;
  grid-template-columns: repeat(2, 1fr);
}

.education-info {
  flex: 1;
}

.mx-2 {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.mb-2 {
  margin-bottom: 0.5rem;
}

.me-2 {
  margin-inline-end: 0.5rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mb-6 {
    margin-bottom: 6rem;
}

.grid {
  display: grid;
}

.grid-cols-5 {
  grid-template-columns: repeat(5, minmax(0, 1fr));
}

.gap-5 {
  gap: 1.25rem;
}

.border-l-md {
    border-left: 2px solid #fff;
}

.rounded-full {
  border-radius: 9999px;
}

.bg-gradient-to-bl {
  background-image: linear-gradient(to bottom left, var(--tw-gradient-stops));
}

.from-cyan-500 {
  --tw-gradient-from: #06b6d4 var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(6 182 212 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-50 {
  --tw-gradient-from: #f8fafc var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(248 250 252 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-100 {
  --tw-gradient-from: #f4f4f5 var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(244 244 245 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.via-slate-100 {
  --tw-gradient-to: rgb(241 245 249 / 0)  var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #f1f5f9 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-zinc-200 {
  --tw-gradient-to: rgb(228 228 231 / 0)  var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #e4e4e7 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.to-blue-500 {
  --tw-gradient-to: #3b82f6 var(--tw-gradient-to-position);
}

.to-slate-200 {
  --tw-gradient-to: #e2e8f0 var(--tw-gradient-to-position);
}

.to-zinc-300 {
  --tw-gradient-to: #d4d4d8 var(--tw-gradient-to-position);
}

.bg-clip-text {
  -webkit-background-clip: text;
  background-clip: text;
}

.h-40 {
    height: 13rem;
    width: auto;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.font-bold {
  font-weight: 700;
}

.text-transparent {
  color: transparent;
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

@media (min-width: 640px) {
  .sm\:grid-cols-8{
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }
}

@media (min-width: 768px) {
  .md\:grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }
}

@media (min-width: 1024px) {
  .lg\:grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }
}

@media (min-width: 1280px) {
  .xl\:grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr));
  }
} 

@media (max-width: 1025px) {
  .education {
    padding-left: 1rem;
  }

  .education,
  .education-content {
    grid-gap: 2rem;
  }
  .education-container {
    left: auto !important;
  }
}

@media (max-width: 768px) {

    .education-container {
        margin-bottom: 2rem;
    }

    .education-container {
        left: auto !important;
      }

  .education-content {
    flex-direction: column-reverse;
    gap: 3rem;
  }

  .education,
  .education-info {
    width: 100%;
  }

  .education-container h5 {
    font-size: 1.3rem;
    margin-bottom: 2rem;
  }

  .education-h5 {
    margin: 4rem auto;
}

  .education-container::after,
  .education-container::before {
    width: 18rem;
    height: 18rem;
  }
}

@media (max-width: 600px) {
  .education-container {
    padding: 0;
  }
}
