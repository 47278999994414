@tailwind base;
@tailwind components;
@tailwind utilities;

.container {
  width: 100%;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

section {
  margin-bottom: 4rem;
}

.hero-container {
  display: flex;
  align-items: center;
  position: relative;
  padding: 0rem 3.5rem;
  height: 800px;
}

.hero-container::after,
.hero-container::before {
  content: " ";
  width: 28.125rem;
  height: 28.125rem;
  border-radius: 28.125rem;
  background: linear-gradient(90deg, rgb(6 182 212) 0%, #3b82f6 100%);
  position: absolute;
  z-index: -1;
  opacity: 0.3;
  filter: blur(60px);
  -webkit-filter: blur(60px);
}

.hero-container::after {
  top: -3rem;
  left: -5rem;
}

.hero-container::before {
  bottom: 2rem;
  right: 0rem;
}

.hero-content {
  flex: 1 1;
  align-content: center;
}

.highlight-skill {
  flex-direction: column;
}
.hero-content h2 {
  font-size: 3.8rem;
  font-weight: 900;
  line-height: 5rem;
  margin-bottom: 1rem;
}

.hero-content button div {
  font-weight: 1100;
}

.hero-content p {
  width: 80%;
  font-size: 1rem;
  font-weight: 500;
  line-height: 2rem;
}

.hero-img {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  margin-top: 2rem;
  user-select: none;
}

.hero-img > div {
  display: flex;
  align-items: flex-end;
  gap: 2rem;
}

.hero-img > div > img {
  width: 25rem;
  transition: all 0.3s ease;
  border-radius: 20%;
  -webkit-user-select: none;
  user-select: none;
}

.hero-img > div > img:hover,
.tech-icon img:hover {
  transform: translateY(-0.5rem);
}


@media (max-width: 1025px) {

  .hero-content h2 {
    font-size: 3rem;
    line-height: 4rem;
  }

  .hero-content p {
    font-size: 0.9rem;
    line-height: 1.6rem;
  }

  .hero-img > div > img {
    width: 20rem;
  }
}

@media (max-width: 768px) {
  .hero-container {
    flex-direction: column-reverse;
    height: auto;
    padding: 4.5rem;
  }

  .highlight-skill {
    display: flex;
    flex-wrap: wrap;
  }


  .hero-img {
    margin: 2rem 0 2rem 0;
  }

  .hero-container h2 {
    margin-top: 3rem;
  }

  .hero-content h2,
  .hero-content p {
    width: auto;
    text-align: center;
  }

  .hero-container::after,
  .hero-container::before {
    content: " ";
    width: 18rem;
    height: 18rem;
  }

  .hero-container::after {
    top: 0rem;
    left: 0rem;
  }

  .hero-container::before {
    bottom: 2rem;
    right: 0rem;
  }

  hero-img {
    gap: 1rem;
  }

  .hero-img > div {
    gap: 1rem;
  }

  .hero-img > div > img {
    width: 24rem;
  }
}

@media (max-width: 600px) {
  .hero-content h2 {
    font-size: 2.2rem;
    line-height: 3rem;
  }

  .highlight-skill {
    flex-direction: column;
    flex-wrap: nowrap;
    font-size: 0.8rem;
  }
  .hero-content p {
    font-size: 0.8rem;
    line-height: 1.3rem;
  }

  .hero-img {
    gap: 1rem;
  }

  .hero-img > div {
    gap: 1rem;
  }

  .hero-img > div > img {
    width: 24rem;
  }
}

@media (max-width: 500px) {

  .hero-container {
    padding: 1.5rem;
  }

  .hero-content h2 {
    font-size: 2rem;
    line-height: 2.7rem;
  }

  .hero-content p {
    font-size: 0.75rem;
    line-height: 1.2rem;
  }

  .hero-img {
    gap: 1rem;
  }

  .hero-img > div {
    gap: 1rem;
  }

  .hero-img > div > img {
    width: 20rem;
  }
}